import * as React from 'react';
import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Api, BASE_URL } from '../../../api/api';
import History from '../../../components/history/History';
import HistoryItem from '../../../components/history/HistoryItem';

interface HistoryInterface {
	id: string;
	file_name: string;
	created_date: string;
	questions: number;
};

interface HistoryItemInterface {
	id: string;
	file_name: string;
	created_date: string;
	questionData: any[];
};

function HistoryPage() {
	const [data, setData] = React.useState<HistoryInterface[]>();
	const [item, setItem] = React.useState<HistoryItemInterface>();
	const [isClickedRow, setIsClickedRow] = React.useState<boolean>(false);

	const getHistoryData = async () => {
		Api.get(BASE_URL, '/list_all_documents', {})
			.then(resp => {
				console.log("resp", resp)
				setData(resp.data);
			})
			.catch(e => {
			})
	};

	const getHistoryItemData = async (id:string, fileName:string, createdAt:string) => {
		Api.post(BASE_URL, '/list_question_and_answer', {
			// "identifier": "49d650f4-c12b-4e38-a2f9-93d259bc2fd4",
			// "file_name": "Get_Started_With_Smallpdf.pdf"
			"identifier": id,
			"file_name": fileName
		})
			.then(resp => {
				console.log("questionData", resp)
				let questionData = [];
				if (resp?.data?.questions.length > 0){
					for (let i = 0; i < resp.data.questions.length; i++) {
						questionData.unshift({
							question: resp.data.questions[i].question,
							answer: resp.data.answers[i].answer,
							created_date: resp.data.questions[i].createdAt,
						});
					}
				}
				setItem({
					id,
					file_name: fileName,
					created_date: createdAt,
					questionData
				});
			})
			.catch(e => {
			})
	};

	React.useEffect(() => {
		getHistoryData();
		// setData(example);
	}, []);

	const handleClickRow = (id: string, fileName: string, createdAt: string) => {
		getHistoryItemData(id, fileName, createdAt);
		setIsClickedRow(true)
	}

  return (
		<Box sx={{
			minHeight: '100vh'
		}}>
			<Box sx={{
				maxWidth: '1100px',
				px: {xs: '12px', sm: 8},
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh',
				color: 'neutral.100',
				mx: 'auto'
			}}>
				<Typography variant='h3' sx={{
					textAlign: 'center',
					pb: '40px',
					pt: '80px',
					fontSize: '36px',
				}}
				>
					History
				</Typography>
				
				<Box sx={{
					color: 'neutral.100',
					backgroundColor: '#10071E',
					borderRadius: 1,
					border: '1px solid rgba(102, 193, 246, 0.3)',
					boxShadow: '0px 10px 114px -30px rgba(102, 193, 246, 0.1)',
					position: 'relative',
					height: 'calc(100vh - 190px)',
					overflowY: 'auto',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
				>
					{!isClickedRow && (
						<>
							{data && data.length > 0 ?
								<History historyData={data} onClickRow={(id, fileName, createdAt)=>handleClickRow(id, fileName, createdAt)} />
								:
								<CircularProgress size={30} 
									sx={{}}
								/>
							}
						</>
					)}
					{isClickedRow && (
						<>
							{item ? 
								<HistoryItem fileName={item.file_name} uploadedTime={item.created_date} questionData={item.questionData}  onClickBack={()=>setIsClickedRow(false)} />
								:
								<CircularProgress size={30} 
									sx={{}}
								/>
							}
						</>
					)}
				</Box>
      </Box>
    </Box>
  );
}

export default HistoryPage;
