import { Box, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import 'react-toastify/dist/ReactToastify.css';

interface NotificationProps {
	title: string;
	detail: string;
	type: string;
}
export const NotificationAlert = (props: NotificationProps) => {
  const { title, detail, type } = props;

  return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			py: 3,
			color: '#FFFFFF',
		}}>
			{type === "error" && ( <ErrorOutlineIcon sx={{fontSize: '50px', mr: 2, color: '#BC2F30'}}/> )}
			{type === "warning" && ( <WarningAmberIcon sx={{fontSize: '50px', mr: 2, color: '#ED6868'}}/> )}
			{type === "success" && ( <CheckCircleOutlineIcon sx={{fontSize: '50px', mr: 2, color: '#60EB7F'}}/> )}
			{type === "info" && ( <InfoOutlinedIcon sx={{fontSize: '50px', mr: 2, color: '#3056E0'}}/> )}
			<Typography sx={{
				fontWeight: 600,
				fontSize: '14px',
				lineHeight: '18px',
				mt: '18px'
			}}>
				{title}
			</Typography>
			<Typography sx={{fontSize: '14px', lineHeight: '18px', textAlign: 'center', pt:'12px', px: '56px'}}>
				{detail}
			</Typography>
		</Box>
  );
};
