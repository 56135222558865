import { useState } from 'react';
import type { FC } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { Avatar, Box, Card, CardMedia, Link, Typography } from '@mui/material';

interface ChatMessageProps {
  authorType: string;
  body: JSX.Element;
  contentType: string;
}

export const ChatMessage: FC<ChatMessageProps> = (props) => {
  const { body, contentType, authorType, ...other } = props;
  const [expandMedia, setExpandMedia] = useState<boolean>(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: authorType === 'assistant'
          ? 'row-reverse'
          : 'row',
        maxWidth: 700,
        width: 'fit-content',
        ml: authorType === 'user' ? 'auto' : 0,
        mb: 2
      }}
      {...other}
    >
      <Box>
        <Card
          sx={{
            background: authorType === 'user'
              ? 'linear-gradient(117.44deg, #22073D -6.61%, #0E0D38 99.5%)'
              : 'linear-gradient(117.44deg, #150425 -6.61%, #020116 99.5%)',
            color: '#FFFFFF',
            p: 2,
            border: '1px solid rgba(102, 193, 246, 0.3)',
            borderRadius: 2
          }}
        >
          {
            contentType === 'image'
              ? (
                <CardMedia
                  onClick={(): void => setExpandMedia(true)}
                  // image={body}
                  sx={{ height: 200 }}
                />
              )
              : (
                <>
                  {body}
                </>
              )
          }
        </Card>
        <Box
          sx={{
            display: 'flex',
            justifyContent: authorType === 'user'
              ? 'flex-end'
              : 'flex-start',
            mt: 1,
            px: 2
          }}
        >
          <Typography
            color="textSecondary"
            noWrap
            variant="caption"
          >
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
