import * as React from 'react';
import {
  Box,
  Tooltip,
  Typography,
	ButtonGroup,
	Button,
	FormControlLabel,
	Switch,
	Tabs,
	IconButton,
  CircularProgress
} from '@mui/material';
import { keyframes } from "@emotion/react";
import { Api, BASE_URL } from '../../api/api';
import { ChatMessages } from '../../components/chat-messages';
import { ChatMessageAdd } from '../../components/chat-message-add';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { homeSliceActions } from '../Home/slice';
import PDFIcon from '../../assets/pdficon.svg';
import CodeIcon from '../../assets/codesnip.svg';
import { SocketContext } from '../../routes/context';
import { selectMessage } from '../Home/selectors';
import { SearchInput } from '../../components/search-input';
import {OptionPanelProps, EmbedStepItemProps} from './types';

function OptionPanel(props: OptionPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      hidden={value !== index}
      {...other}
    >
			{children}
    </Box>
  );
}

function Search() {

	const [isLoading, setLoading] = React.useState<boolean>(true);
	const [sending, setSending] = React.useState<boolean>(false);
	const [fileNameShort, setFileNameShort] = React.useState<string>('');
	const [activeOptionID, setActiveOptionID] = React.useState<number>(0);
	const [histories, setHistory] = React.useState<any[]>([]);
	const messagesRef = React.useRef<any>(null);
	const [thread, setThread] = React.useState<any>([]);
	const messagesSearchRef = React.useRef<any>(null);
	const [searchResult, setSearchResult] = React.useState<any>([]);
	const [searched, setSearched] = React.useState<boolean>(false);
	const dispatch = useDispatch();
	const params = useParams().id;
	const identifier = params?.split(':')[0];
	const fileName = params?.split(':')[1];

	const {
		sendChatMessage
    } = React.useContext(SocketContext);
	const messageData = useSelector(selectMessage)

  const [value, setValue] = React.useState(0);

  const handleOptionChange = (id: number) => {
    setValue(id);
		setActiveOptionID(id);
  };

	React.useEffect(()=>{
		if(messageData.role){
			setLoading(false);
			setSending(false);
			setThread((prev: any) => {
				return [...prev, {...messageData, type: activeOptionID === 1 || prev.length === 0 ? 'chat' : 'question'}]
			});
			setHistory((prev: any) => {
				return [...prev, {
					author: messageData.role === 'assistant' ? 'AI' : "Human",
					msg: messageData?.content
				}]
			});
		}
	},[messageData]);

	const bounce = keyframes`
		0% {
			transform: translateY(0px);
		}
		50% {
			transform: translateY(8px);
		}
		100% {
			transform: translateY(0px);
		}
	`;

	React.useEffect(() => {
    const element = messagesRef.current;
		if (element)
    element.scrollTop = element.scrollHeight;
  }, [messagesRef.current?.scrollHeight]);

	React.useEffect(() => {
		if(params) {
			if(identifier && fileName) {
				let split = fileName.split('.');
				split.pop();
				setFileNameShort(split.join("."));
				sendChatMessage({identifier: identifier, fileName: fileName, history:histories, question:null})
			}
			dispatch(homeSliceActions.setPdfParsed({}));
		}
	}, [])

	// search option
	const handleSearchMessage = (str: string) => {
		if (!str) {
			setSearchResult([]);
		}
		else {
			if (thread.length > 0) {
				if (thread[0].role === 'assistant' ) {
          let exampleQuestions = thread[0].questions;
					let foundQuestions = exampleQuestions.filter((question: string) => question.toLowerCase().includes(str.toLowerCase()))
					setSearchResult(foundQuestions);
				}
			}
		}
	};

	const handleSearchSendMessage = React.useCallback((msg: string) => {
		if (msg) {
			setSending(true);
			setSearched(true);
			setSearchResult([]);
			setThread((prev: any) => {
				return [...prev, {
					role: 'user',
					content: msg,
					type: 'question'
				}]
			});
			setHistory((prev: any) => {
				return [...prev, {
					author: "Human",
					msg: msg,
					type: 'question'
				}]
			});
			sendChatMessage({identifier: identifier, fileName: fileName, history: histories, question:msg})
		}
	}, [histories, setThread, setSending, setHistory, identifier, fileName]);

	React.useEffect(() => {
    const element = messagesSearchRef.current;
		if (element)
    element.scrollTop = element.scrollHeight;
  }, [messagesSearchRef.current?.scrollHeight, thread]);

	const handleSearchScrollDown = () => {
		const element = messagesSearchRef.current;
    element.scrollTop = element.scrollHeight;
	}


  return (
    <Box sx={{
			minHeight: '100vh',
      px: 3
		}}>
			<Box sx={{
				maxWidth: '1100px',
				margin: '0 auto',
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh',
			}}>
				<Box sx={{mt: 3}}></Box>
				<Box sx={{
					color: 'neutral.100',
					backgroundColor: '#10071E',
					borderRadius: 1,
					border: '1px solid rgba(102, 193, 246, 0.3)',
					boxShadow: '0px 10px 114px -30px rgba(102, 193, 246, 0.1)',
					position: 'relative',
          minHeight: '580px'
				}}
				>
					<Box>
						<OptionPanel value={value} index={0}>
							<Box
								sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                  p: 3,
								}}
							>
								<Box sx={{ display: 'flex', alignItems: 'center'}}>
									{(thread && thread?.length > 0 && thread[0].company) ? 
										<>
											<Typography
												sx={{
													fontSize: { xs: '1rem', lg: '1.125rem' },
													textAlign: 'left',
													pl: 2,
												}}
											>
												Search {thread[0].company === '' ? 'Company' : thread[0].company}
											</Typography>
										</>
										:
										<CircularProgress
											size={20}
											sx={{
												// position: 'absolute',
												top: 0,
												bottom: 0,
												margin: 'auto 0',
												right: '50px'
											}}
										/>
									}
								</Box>
							</Box>
							<Box
								sx={{
									position: 'relative',
                  minHeight: '580px'
								}}
							>
								<SearchInput
									sx={{position: 'absolute', width: 'calc(100% - 48px)', top: '15px', mx: 3 }}
									onSearch={handleSearchMessage}
									questions={searchResult || []}
									onSend={handleSearchSendMessage}
									disabled={isLoading || sending}
								/>
								{searched ? (
									<Box
										sx={{
											height: '100%',
											pt: '80px',
											pb: '24px'
										}}
									>
											<Box
												ref={messagesSearchRef}
												sx={{
													height: '100%',
													overflow: 'auto',
													'&::-webkit-scrollbar-track':
													{
														borderRadius: '10px',
														backgroundColor: '#555'
													},
													'&::-webkit-scrollbar':
													{
														width: '8px',
														backgroundColor: 'transparent'
													},
													'&::-webkit-scrollbar-thumb':
													{
														borderRadius: '10px',
														backgroundColor: '#F5F5F5'
													}
												}}
											>
												<ChatMessages
													messages={thread.filter((item: any) => item.type === 'question') || []}
													scrollDown={handleSearchScrollDown}
												/>
												{(isLoading || sending) && (
													<Box
														sx={{
															pl: 3,
															pb: 3,
															'& span': {
																width: '6px',
																height: '6px',
																backgroundColor: '#D9D9D9',
																display: 'inline-block',
																mr: '4px',
																borderRadius: '50%',
																'&:nth-child(1)': {
																	animation: `${bounce} 1s infinite`,
																},
																'&:nth-child(2)': {
																	animation: `${bounce} 1s infinite .2s`,
																},
																'&:nth-child(3)': {
																	animation: `${bounce} 1s infinite .4s`,
																}
															}
														}}
													>
														<span></span>
														<span></span>
														<span></span>
													</Box>
												)}
											</Box>
										</Box>
									) : (
										<Box
											sx={{
												'& img': {
													width: '150px'
												},
												minHeight: '580px',
												textAlign: 'center',
												display: 'flex'
											}}
										>
											<Box
												sx={{
													margin: 'auto'
												}}
											>
												<img
													alt="code"
													src={CodeIcon}
												/>
												<Typography
													sx={{
														fontSize: {xs: '18px', sm: '25px'},
														lineHeight: {xs: '19px', sm: '19px'},
														fontWeight: 600,
														py: 2
													}}
												>
													Search your Questions
												</Typography>
											</Box>
										</Box>
									)}
							</Box>
						</OptionPanel>
					</Box>
				</Box>
			</Box>
    </Box>
  );
}

export default Search;
