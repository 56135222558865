import { useState, useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

interface TypingAnimationProps {
	text: string;
	renderAfter?: any;
	handleDone?: (value: boolean) => void;
	handleDoing?: () => void;
}

export const TypingAnimation: FC<TypingAnimationProps> = (props) => {
	const { text, renderAfter, handleDone, handleDoing, ...other } = props;
	let arr = text.trim().split(" ");
	const [textRendered, setTextRendered] = useState<string>('');
	const [isRendered, setIsRendered] = useState<boolean>(false);

	useEffect(() => {
		let index = 0;
		const interval = setInterval(() => {
			index++; 
			setTextRendered(arr.slice(0, index).join(" ")); 
			handleDoing?.();
			if (index === arr.length) {
				setIsRendered(true);
				handleDone?.(true);
				clearInterval(interval);
			}
		}, 100)
		return () => clearInterval(interval);
	}, [])

  return (
	<>
		<Typography {...other}
			sx={{
				fontSize: {xs: '11px', sm: '14px'},
				lineHeight: {xs: '19px', sm: '23px'},
			}}
		>{textRendered}{!isRendered? "...":""}</Typography>
		{isRendered && renderAfter && (
			<>{renderAfter}</>
		)}
	</>

  );
};

TypingAnimation.propTypes = {
  text: PropTypes.any,
};
