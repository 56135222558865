import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
// import { createTheme } from '@mui/material/styles';
import { createTheme } from './theme';
import Routers from './routes/Routes';
import './App.css';

function App() {
  return (
    <ThemeProvider
        theme={createTheme({
          mode: 'dark'
        })}
      >
        <BrowserRouter>
          <Suspense>
            <Routers />
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
  );
}

export default App;
