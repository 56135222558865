import * as React from 'react';
import { AxiosProgressEvent } from 'axios';
import {
  Box,
  Typography,
	LinearProgress
} from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { FileDropzone } from '../../components/DropZone';
import { Api, BASE_URL } from '../../api/api';
import { selectPdfParsedData, selectUserId } from './selectors';
import { useSelector, useDispatch } from 'react-redux';
import Img1 from '../../assets/step1.svg';
import Img2 from '../../assets/step2.svg';
import Img3 from '../../assets/step3.svg';
import Img4 from '../../assets/step4.svg';
import { toast } from 'react-toastify';
import EastIcon from '@mui/icons-material/East';
import { homeSliceActions } from './slice';
import { NotificationAlert } from '../../components/notifications/NotificationAlert';

interface DetailItemProps extends BoxProps {
  image: string;
	step: string;
	title: string;
	detail: string;
}

function DetailItem(props: DetailItemProps) {
  const { sx, image, step, title, detail, ...other } = props;
  return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: {xs: 'center', sm: 'center', md: 'start', lg: 'start'},
			maxWidth: {xs: '300px', sm: '360px'},
			margin: '0 auto'
			}}
			{...other}
		>
			<img
				alt="step 1"
				src={image}
			/>
			<Typography
				sx={{
					color: '#66C1F6',
					fontWeight: '700',
					fontSize: {xs:'13px', sm: '13px', md:'13px', lg:'13px'},
					lineHeight: {xs:'20px', sm: '20px', md:'20px', lg:'20px'},
					letterSpacing: '0.5em',
					pt: {xs: 1, sm: '13px', md:'24px', lg:'24px'},
				}}
			>
				{step}
			</Typography>
			<Typography
				sx={{
					fontSize: {xs:'20px', sm: '19px', md:'14px', lg:'14px'},
					lineHeight: {xs:'22px', sm: '18px', md:'22px', lg:'22px'},
					fontWeight: 600,
					pt: {xs:'17px', sm: '13px', md:'24px', lg:'24px'},
				}}
			>
				{title}
			</Typography>
			<Typography
				sx={{
					fontSize: {xs:'14px', sm: '11px', md:'11px', lg:'11px'},
					lineHeight: {xs:'22px', sm: '18px', md:'21px', lg:'21px'},
					fontWeight: 300,
					textAlign: {xs: 'center', sm: 'center', md: 'start', lg: 'start'},
					pt: {xs:'17px', sm: '13px', md:'24px', lg:'24px'},
				}}
			>
				{detail}
			</Typography>
		</Box>
  );
}

function Home() {

  const [files, setFiles] = React.useState<any[]>([]);
	const [isLoading, setLoading] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);
	const navigate = useNavigate();

	const handleClickLottery = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
		// dev
		// navigate('/chat/44b0e7b7-1889-4a3b-a6e6-c0a3f2b006c0:Lottery%20of%20the%20People%20FAQ.pdf')
		// prod
		navigate('/chat/004a9dee-4fb7-4331-8164-ac2081afb8dd:Lottery%20of%20the%20People%20FAQ.pdf')
	}

	const handleDrop = (newFiles: any): void => {
		if(newFiles.length === 0) {
			toast.error(<NotificationAlert type="error" title="Unsupported file format!" detail="We are currently support .pdf, .txt file format."/>)
		} else {
			setFiles((prevFiles) => [...prevFiles, ...newFiles]);
		}
  };
	const dispatch = useDispatch()
  const userId = useSelector(selectUserId)
  const pdfParsedData = useSelector(selectPdfParsedData)

  const handleRemove = (file: any): void => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };
  const [uploadData, setUploadData] = React.useState({
		contentType: '',
		fileName: '',
		enableContinueButton: false
  })

  const handleRemoveAll = (): void => {
    setFiles([]);
  };

	const uploadPDF = async (fileName: string, file: any, contentType:string) => {
		setLoading(true)
		await Api.post(BASE_URL, '/sign_url', {fileName: fileName,contentType}, {
			onUploadProgress: (event:AxiosProgressEvent) => {
				if(event.total){
					setProgress(Math.round(
						(10 * event.loaded) / event.total
					));
				}
			},
			onDownloadProgress: (event:AxiosProgressEvent) => {
				if(event.total){
					setProgress(10 + Math.round(
						(5 * event.loaded) / event.total
					));
				}
			}
		}
	)
		.then(async (resp) => {
			setUploadData({contentType:'',fileName:'', enableContinueButton: false})
			if(resp.statusCode === 200) {
				console.log(resp);
				await Api.put(resp.body.url, '', file, {
					onUploadProgress: (event:AxiosProgressEvent) => {
						if(event.total){
							setProgress(15 + Math.round(
								(85 * event.loaded) / event.total
							));
						}
					},
					headers: {
						'Content-Type': 'application/pdf'
					}
				})
				.then(response => {
					setTimeout(() => {
						toast.success(<NotificationAlert type="success" title="Document successfully upload!" detail={"Your document is being processed."}/>)
					}, 5000)
				})
				.catch(err => {
					toast.error(<NotificationAlert type="error" title="Document upload failed!" detail={err.message}/>)
				})
			} else {
				// @Yevheni display error message
				setLoading(false);
			}
		})
		.catch(err => {
			setUploadData({contentType:'',fileName:'', enableContinueButton: false})
		})
	}

	React.useEffect(() => {
		if(files && files.length > 0) {
			const fileName = files[0].path.replace('~', '');
			const fileExtenstion = fileName.split('.')
			if(fileExtenstion[1]==='pdf'){
				// checkFileSize(`${userId}~${fileName}`, files[0], "application/pdf");
				uploadPDF(`${userId}~${fileName}`, files[0], "application/pdf");
			}else if(fileExtenstion[1]==='txt'){
				// checkFileSize(`${userId}~${fileName}`, files[0], "text/plain");
				uploadPDF(`${userId}~${fileName}`, files[0], "text/plain");
			}
			
		}
	}, [files])

	React.useEffect(()=>{
		if(pdfParsedData && pdfParsedData.fileName && pdfParsedData.userId === userId){
			if(pdfParsedData.pdfParsed){
				navigate(`/chat/${userId}:${pdfParsedData.fileName}`);
			}
			else {
				toast.error(pdfParsedData.message);
			}
		}
		
	},[pdfParsedData]);

	React.useEffect(() => {
		window.onpageshow = function(event) {
			if (event.persisted) {
				window.location.reload();
			}
		};
	}, []);

	React.useEffect(() => {
		dispatch(homeSliceActions.initState())
	}, [])

  return (
		<Box sx={{
			minHeight: '100vh'
		}}>
      <Box sx={{ pb: 5, px: {xs: 2, sm: 8, md: 8, lg: 8}, color: 'neutral.100' }}>
				<Box sx={{maxWidth: {xs: '100%', md: '580px', lg: '580px'}, margin: '0 auto'}}>
					<Typography
						sx={{
							fontWeight: '600',
							fontSize: { xs: '19px', sm: '36px', lg: '36px' },
							lineHeight: { xs: '31px', sm: '51px', lg: '51px' },
							textAlign: 'center',
							pt: {xs: '77px', sm: '64px', md: '94px', lg: '94px'}
						}}
					>
						Talk to your FAQ with GPT
					</Typography>
					<Typography
						sx={{
							fontWeight: '400',
							fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '18px' },
							lineHeight: { xs: '31px', sm: '18px', md: '31px', lg: '31px' },
							textAlign: 'center',
							pt: {xs: '22px', sm: '25px', md: '27px', lg: '27px'}
						}}
					>
						Test and embed free
					</Typography>
					<Box sx={{
							display: 'flex',
							justifyContent: 'center'
						}}
						onClick={handleClickLottery}
					>
						<Box sx={{
							display: 'flex',
							width: 'fit-content',
							color: '#66C1F6',
							fontSize: '12px',
							cursor: 'pointer',
							pt: 3
						}}>
							<Typography sx={{
								textDecoration: 'underline'
							}}>
								Try a Demo FAQ
							</Typography>
							<EastIcon fontSize='small' sx={{ml: 2}}/>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						mt: {xs: '22px', sm: '39px', md: '54px', lg: '54px'},
						p: 1,
						backgroundColor: 'background.default',
						borderRadius: 1,
						boxShadow: '0px 10px 84px 40px rgba(102, 193, 246, 0.1)',
						maxWidth: '1000px',
						margin: '0 auto'
					}}
				>
					<FileDropzone
						isLoading={isLoading}
						accept={["application/pdf","text/plain"]}
						files={files}
						onDrop={handleDrop}
						onRemove={handleRemove}
						onRemoveAll={handleRemoveAll}
					/>
					{isLoading && (
						<LinearProgress variant="determinate" value={progress} />
					)}
				</Box>
				<Box
					sx={{
						pt: {xs: '20px', sm: '44px', md: '3vh', lg: '3vh'},
						display: 'grid',
						gridAutoFlow: 'row',
						gridTemplateColumns: {sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(4, 1fr)'},
						gap: {xs: 5, sm: 4, lg: 8},
						textAlign: {xs: 'center', sm: 'start', lg: 'start'},
						'& img': {
							width: {xs:'200px', sm:'250px', md:'90px', lg:'90px'},
							height:  {xs:'200px', sm:'250px', md:'90px', lg:'90px'},
						}
					}}
				>
					<DetailItem
						image={Img1}
						step="STEP 1"
						title="Upload Your Knowledge Base"
						detail="Submit a single PDF file containing your knowledge base, and GPT will analyze it to create a customer GPT chatbot focused on your knowledge base."
					/>
					<DetailItem
						image={Img2}
						step="STEP 2"
						title="Preview Your Chatbot"
						detail="Test the chatbot's performance and make adjustments to improve its FAQ handling."
					/>
					<DetailItem
						image={Img3}
						step="STEP 3"
						title="Generate Your Embed Code"
						detail='Click "Embed Script" to receive a custom code snippet for your GPT FAQ chatbot.'
					/>
					<DetailItem
						image={Img4}
						step="STEP 4"
						title="Embed Your Chatbot Widget"
						detail="Paste the code snippet into your website's HTML where you want FAQ GPT to appear."
					/>
					{/* <DetailItem
						image={Img5}
						step="STEP 5"
						title="Monitor and Improve"
						detail="Use analytics to track performance, user interactions, and refine your knowledge base."
					/> */}
				</Box>
      </Box>

    </Box>
  );
}

export default Home;
