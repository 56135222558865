import * as React from 'react';
import { AxiosProgressEvent } from 'axios';
import {
  Box,
  Container,
  Typography,
	LinearProgress
} from '@mui/material';
import { FileDropzone } from '../../components/DropZone';
import { Api, BASE_URL } from '../../api/api';
import { ChatMessages } from '../../components/chat-messages';
import { Scrollbar } from '../../components/scrollbar';
import { ChatMessageAdd } from '../../components/chat-message-add';

function EmbedChat() {

	const [isFileUploaded, setFileUploaded] = React.useState<boolean>(false);
	const [isLoading, setLoading] = React.useState<boolean>(true);
	const [sending, setSending] = React.useState<boolean>(false);
	const [histories, setHistory] = React.useState<any[]>([]);
	const messagesRef = React.useRef<any>(null);
	const [thread, setThread] = React.useState<any>([]);

	const [files, setFiles] = React.useState<any[]>([]);
	const [isFileLoading, setFileLoading] = React.useState<boolean>(false);
	const [fileNameInfo, setFileNameInfo] = React.useState<string>('');
	const [fileIDInfo, setFileIDInfo] = React.useState<string>('');
  const [progress, setProgress] = React.useState<number>(0);
	const handleDrop = (newFiles: any): void => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file: any): void => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll = (): void => {
    setFiles([]);
  };

	const checkParse = async (identifier: string, fileName: string) => {
		await Api.post(BASE_URL, '/check', {body: {identifier, fileName}}, {
			onUploadProgress: (event:AxiosProgressEvent) => {
				if(event.total){
					setProgress(90 + Math.round(
						(10 * event.loaded) / event.total
					));
				}
			}
		})
			.then(resp => {
				console.log("check response ======>", resp)
				if(resp.statusCode === 200) {
					// getAnswer(identifier, [], '');
					setFileLoading(false);
					setFileUploaded(true);
					setFileNameInfo(fileName);
					setFileIDInfo(identifier);
				} else if(resp.statusCode === 404) {
					checkParse(identifier, fileName)
				}
			})
			.catch(err => {
					// @Yevheni display error message
					setFileLoading(false);
			})
	}

	const uploadPDF = async (base64: string, fileName: string) => {
		setFileLoading(true)
		await Api.post(BASE_URL, '/upload', {body: {base64Image: base64, fileName}}, {
				onUploadProgress: (event:AxiosProgressEvent) => {
					console.log("progress",event.loaded);
					if(event.total){
						console.log("event.total", event.total)
						setProgress(Math.round(
							(80 * event.loaded) / event.total
						));
					}
				},
				onDownloadProgress: (event:AxiosProgressEvent) => {
					if(event.total){
						setProgress(80 + Math.round(
							(10 * event.loaded) / event.total
						));
					}
				}
			}
		)
			.then(resp => {
				console.log("response ========>", resp);
				if(resp.statusCode === 200) {
					setTimeout(() => {
						checkParse(resp.body.identifier, resp.body.fileName)
					}, 2000)
				} else {
					// @Yevheni display error message
					setFileLoading(false);
				}
			})
			.catch(err => {
				console.log("error ===========>", err);
			})
	}

	React.useEffect(() => {
		console.log("files ========>", files);
		if(files && files.length > 0) {
			let reader = new FileReader();
			reader.readAsDataURL(files[0]);
			const fileName = files[0].path.replace('~', '');
			reader.onload = function(data: any) {
				let base64 = data.currentTarget.result?.replace('data:application/pdf;base64,', '');
				uploadPDF(base64, fileName);
			}
			reader.onerror = function(error) {
				console.log("error ==========", error)
			}
		}
	}, [files])


	const getAnswer = React.useCallback(async(id: string, fileName: string, histories: any = [], question: string | null) => {
		await Api.post(BASE_URL, '/chat', {body: {identifier: id, fileName, history: histories, question: question}})
			.then(resp => {
				if(resp.statusCode === 200) {
					setLoading(false);
					setSending(false);
					setThread((prev: any) => {
						return [...prev, resp.body.msg]
					});
					setHistory((prev: any) => {
						return [...prev, {
							author: resp.body.msg.role === 'assistant' ? 'AI' : "Human",
							msg: resp.body.msg.content
						}]
					});
				}
			})
			.catch(err => {

			})
	}, [setThread, setHistory, setLoading])

	const handleSendMessage = React.useCallback((msg: string) => {
		setSending(true);
		setThread((prev: any) => {
			return [...prev, {
				role: 'user',
				content: msg
			}]
		});
		getAnswer(fileIDInfo, fileNameInfo, histories, msg)
		if (messagesRef?.current) {
			const scrollElement = messagesRef.current.getScrollElement();

			scrollElement.scrollTo({
				top: messagesRef.current.el.scrollHeight,
				behavior: 'smooth'
			});
		}
	}, [getAnswer, histories, setThread, setSending, fileNameInfo]);

	React.useEffect(
    () => {
      if (thread && messagesRef?.current) {
        const scrollElement = messagesRef.current.getScrollElement();
        scrollElement.scrollTop = messagesRef.current.el.scrollHeight;
      }
    },
    [thread]
  );

	React.useEffect(() => {
		if(fileIDInfo && fileNameInfo) {
			getAnswer(fileIDInfo, fileNameInfo, histories, null)
		}
	}, [])

  return (
		<Box sx={{
			backgroundColor: 'neutral.900',
			borderRadius: 1,
			position: 'fixed',
			bottom: '10px',
			right: '10px',
			height: '800px',
			maxWidth: '400px',
		}}>
			{!isFileUploaded &&
				<Box sx={{ py: 5, color: 'neutral.100', height: '100%', display: 'flex', alignItems: 'center' }}>
					<Box>
						<Typography
							sx={{
								fontSize: { xs: '1rem', md: '4rem' },
								textAlign: 'center',
								py: 2,
							}}
						>
							GPT FAQ
						</Typography>
						<Box sx={{ px: 2, py: 2 , backgroundColor: 'neutral.900', borderRadius: 1 }}>
							<FileDropzone
								isLoading={isFileLoading}
								accept="application/pdf"
								files={files}
								onDrop={handleDrop}
								onRemove={handleRemove}
								onRemoveAll={handleRemoveAll}
							/>
							{isFileLoading && (
								<LinearProgress variant="determinate" value={progress} />
							)}
						</Box>
					</Box>
				</Box>
			}

			{isFileUploaded &&
				<Container sx={{ color: 'neutral.100', height: '100%', position: 'relative'}}>
					<Typography
						sx={{
							fontSize: { xs: '1rem', md: '1.5rem' },
							textAlign: 'left',
							py: 1,
							overflow: 'hidden',
   						display: '-webkit-box',
							lineClamp: 1,
							WebkitLineClamp: 1,
							WebkitBoxOrient:'vertical'
						}}
					>
						Chat with {fileNameInfo}
					</Typography>
					<Box
						sx={{
							backgroundColor: 'background.default',
							flexGrow: 1,
							overflow: 'hidden',
							height: 'calc(100% - 144px)'
						}}
					>
						<Scrollbar
							ref={messagesRef}
							sx={{ maxHeight: 'calc(100% - 12px)' }}
						>
							<ChatMessages
								messages={thread || []}
								onExampleSend={handleSendMessage}
							/>
						</Scrollbar>
					</Box>
					<ChatMessageAdd
						isLoading={sending}
						disabled={isLoading || sending}
						onSend={handleSendMessage}
					/>
				</Container>
			}
    </Box>
  );
}

export default EmbedChat;
