import type { FC } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import type { Message, Participant } from '../types/chat';
import { PaperAirplane as PaperAirplaneIcon } from '../icons/paper-airplane';
import { ChatMessage } from './chat-message';
import { TypingAnimation } from './typing-animation';
import { ErrorChatMessage } from './error-chat-message';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Document } from 'langchain/document';

interface SourceProps {
  source: string;
}

interface ChatMessagesProps {
  messages: any[];
  onExampleSend?: (value: string) => void;
  onExampleEdit?: (value: string, id: number) => void;
  scrollDown?: () => void;
  disableAnimation?: boolean;
  disableInitialMsg?: boolean;
  // participants: any[];
}

const SourceDropdown: FC<SourceProps> = (props) => {
  const { source, ...other } = props;
  const [expand, setExpand] = useState<boolean>(false);
  return(
    <Box sx={{
      background: '#120638',
      borderRadius: '3px',
      width: 'fit-content'
    }}>
      <Box sx={{
        display: 'flex',
        p: 1,
        width: 'fit-content',
        cursor: 'pointer'
      }}
      onClick={()=>setExpand(prev=>!prev)}
      >
        <Typography>Source</Typography>
        {!expand && <KeyboardArrowRightIcon />}
        {expand && <KeyboardArrowDownIcon />}
      </Box>
      {expand && 
        <>
          <Box sx={{
            display: 'flex',
            p: 2,
            width: 'fit-content'
          }}>
            <Typography sx={{
              fontSize: '13px'
            }}>{source.split("page_content=")[1]?.split("metadata=")[0]?.replace(/\\n/g,'\n')?.replaceAll("'", '')}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            p: 2,
            width: 'fit-content'
          }}>
            <Typography sx={{
              fontSize: '13px'
            }}>
              {source.split("metadata=")[1]?.split('~')[1]?.replace("'", '')?.replace('}', '')}
            </Typography>
          </Box>
        </>
      }
    </Box>
  )
}

export const ChatMessages: FC<ChatMessagesProps> = (props) => {
  const { messages, onExampleSend, onExampleEdit, scrollDown, disableAnimation, disableInitialMsg, ...other } = props;
  const [fistMsgRendered, setFistMsgRendered] = useState<boolean>(false);
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  const user = {
    avatar: '/static/mock-images/avatars/avatar-anika_visser.png',
    name: 'Anika Visser'
  };
  let exampleQuestions:string[];

  return (
    <Box
      sx={{ p: 3, pb: 0 }}
      {...other}
    >
      {messages.map((message, index) => {
        let contentFirst;
        if (message.questions && message.role === 'assistant') {
          contentFirst = message.content;
          exampleQuestions = message.questions;
        }
        return (
          <Box key={`message_${index}`}>
          {message.status && message.questions && message.role === 'assistant' &&
            <ChatMessage
              authorType={message.role}
              body={
                    <Box>
                      {disableAnimation ? (
                        <>
                          <Typography
                            color="inherit"
                            variant="body1"
                            sx={{
                              fontSize: {xs: '11px', sm: '14px'},
                              lineHeight: {xs: '19px', sm: '23px'},
                            }}
                          >
                            {contentFirst}
                          </Typography>
                          {exampleQuestions.map((question, questionIndex) => {
                            return (
                              <Typography
                                key={`question_${questionIndex}`}
                                sx={{
                                  fontSize: {xs: '11px', sm: '14px'},
                                  lineHeight: {xs: '19px', sm: '23px'},
                                  pt: 1,
                                  cursor: 'pointer',
                                }}
                                onClick={()=>onExampleSend?.(question)}
                              >
                                <PaperAirplaneIcon fontSize="small" sx={{pr:1}}/>
                                  {question}
                              </Typography>
                            )
                          })}
                        </>
                      ) : (
                        <>
                          <TypingAnimation handleDoing={scrollDown} text={contentFirst} handleDone={()=> {setFistMsgRendered(true)}} />
                          {fistMsgRendered && exampleQuestions.map((question, questionIndex) => {
                              return (
                                <Box
                                key={`question_${questionIndex}`}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  pt: 1,
                                  cursor: 'pointer',
                                }}>
                                  <Typography
                                    sx={{
                                      fontSize: {xs: '11px', sm: '14px'},
                                      lineHeight: {xs: '19px', sm: '23px'},
                                      display: 'flex',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                    }}
                                    onClick={()=>onExampleSend?.(question)}
                                  >
                                    <PaperAirplaneIcon fontSize="small" sx={{pr:1}}/>
                                      {question}
                                  </Typography>
                                  <EditIcon fontSize="small" sx={{pl:1}} onClick={()=>onExampleEdit?.(question, questionIndex)}/>
                                </Box>
                              )
                            })
                          }
                        </>
                      )}
                    </Box>
              }
              contentType={'text'}
            />
          }
          {message.status && !message.questions &&
            <ChatMessage
              authorType={message.role}
              body={message.role === 'assistant' && !disableAnimation ?
                <>
                  <TypingAnimation
                    handleDoing={scrollDown}
                    text={message.content}
                    renderAfter={
                      message.source? (
                        <Box sx={{pt: 1}}>
                          <SourceDropdown source={message.source} />
                        </Box>
                      ): (
                        <></>
                      )
                    }
                  />
                </>
                :
                <>
                  <Typography
                    color="inherit"
                    variant="body1"
                    sx={{
                      fontSize: {xs: '11px', sm: '14px'},
                      lineHeight: {xs: '19px', sm: '23px'},
                    }}
                  >
                    {message.content}
                  </Typography>
                  {message.source && (
                    <Box sx={{pt: 1}}>
                      <SourceDropdown source={message.source} />
                    </Box>
                  )}
                </>
              }
              contentType={'text'}
            />
          }
          {!message.status && message.role === 'assistant' &&
            <ErrorChatMessage content={message.content} />
          }
          </Box>
        );
      })}
    </Box>
  );
};

ChatMessages.propTypes = {
  // @ts-ignore
  messages: PropTypes.array,
  // participants: PropTypes.any
};
