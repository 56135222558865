import * as React from 'react';
import {
  Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableContainer,
	Typography
} from '@mui/material';
import PDFIcon from '../../assets/pdficon.svg';

interface HistoryItemInterface {
	id: string;
	file_name: string;
	created_date: string;
	questions: number;
};

interface HistoryProps {
	historyData?: HistoryItemInterface[];
  onClickRow?: (id: string, fileName: string, createdAt: string) => void;
};

const History: React.FC<HistoryProps> = (props) => {
	const { historyData, onClickRow, ...other } = props;
	// const [data, setData] = React.useState<HistoryItemInterface[]>(historyData);

	const convertDateTimeFormat = (isoStr:string) => {
		const dateObject = new Date(isoStr);
		const formattedDate = `${dateObject.getFullYear()}-${('0' + (dateObject.getMonth()+1)).slice(-2)}-${('0' + dateObject.getDate()).slice(-2)}, ${('0' + dateObject.getHours()).slice(-2)}:${('0' + dateObject.getMinutes()).slice(-2)}`;
		return formattedDate
	}

  return (
		<Box sx={{height: '100%', width: '100%'}}>
			<Box sx={{overflowY: 'auto', height: '100%'}}>
			{historyData?.length ? (
      <TableContainer sx={{ maxHeight: '100%' }}>
				<Table stickyHeader sx={{ minWidth: 650, '& td, & th': { borderColor: '#66C1F64D !important', fontSize: '16px', fontWeight: '500', color: '#FFFFFF4D' } }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell sx={{background: '#10071E', pl: 5}} width="54%">Document name</TableCell>
							<TableCell align="left" sx={{background: '#10071E'}} width="23%">Uploaded time</TableCell>
							<TableCell align="left" sx={{background: '#10071E'}} width="23%">Questions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{historyData.map((row) => (
							<TableRow
								key={`tablerow_${row.id}_${row.file_name}`}
								sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
								onClick={() => onClickRow?.(row.id, row.file_name, row.created_date)}
							>
								<TableCell component="th" scope="row">
									<Box sx={{
										display: 'flex',
										alignItems: 'center',
										color: '#FFFFFF',
										fontSize: '18px',
										fontWeight: '500',
										pl: 3,
										'& img': {
											width: {xs: '20px', sm: '29px'},
											mr: 2
										}
									}}>
										<img
											alt="pdf"
											src={PDFIcon}
										/>
									{row.file_name}
									</Box>
								</TableCell>
								<TableCell align="left">{row.created_date?convertDateTimeFormat(row.created_date) : ''}</TableCell>
								<TableCell align="left">{row.questions}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			) : (
				<></>
			)}
			</Box>
		</Box>
  );
}

export default History;
