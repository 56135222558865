import axios from 'axios';
export const BASE_URL = process.env.REACT_APP_API_URL || ""
const Axios = axios.create();
Axios.defaults.baseURL = BASE_URL;

const handleError = (path: string, error: any) => {
  document.dispatchEvent(
    new CustomEvent('appError', {
      bubbles: true,
      detail: { error },
    })
  );
  throw error;
};


export const Api = {
  get: (url: string, path: string, queries: any) => {
    if (path) url += path;
    if(typeof(queries) === 'object') {
      Object.keys(queries).map((key, index) => {
        if(index === 0) {
          url += `?${key}=${queries[key]}`
        } else {
          url += `&${key}=${queries[key]}`
        }
      })
    } else {
      if(queries) {
        url += queries;
      }
    }
    return Axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => handleError(url, error))
  },
  post: (url: string, path: string, data: any, config?:any) => {
    if(path) url+= path;
    return Axios
      .post(url, data, config)
      .then((response) => response.data)
      .catch((error) => handleError(url, error))
  },
  put: (url: string, path: string, data: any, config?:any) => {
    if(path) url+=path;
    return Axios
      .put(url, data, config, )
      .then((response) => response.data)
      .catch((error) => handleError(url, error))
  }
}
