/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Home from '../pages/Home/Home';
import Chatting from '../pages/Chatting/Chatting';
import EmbedChat from '../pages/EmbedChat/EmbedChat';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserId } from '../pages/Home/selectors';
import { homeSliceActions } from '../pages/Home/slice';
import { SocketContext } from './context';
import OpenAILogo from '../assets/openai.svg';
import Search from '../pages/Search/Search';
import Analytics from '../pages/Analytics/Analytics';
import { ToastNotification } from '../components/notifications/ToastNotification';
import HistoryPage from '../pages/Admin/History/HistoryPage';

const WS_URL = process.env.REACT_APP_SOCKET_URL || "";
function Routers() {


  const userId = useSelector(selectUserId)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {sendMessage,sendJsonMessage, lastJsonMessage, readyState, getWebSocket} = useWebSocket(WS_URL, {
    share: true,
    onOpen: (event) => {
      console.log('WebSocket connection established.', event);
      // sendJsonMessage({action:"send-message",data:{body:{test:"1234"}}})
    },
    queryParams:{
      userId
    }
  });

  useEffect(() => {
    console.log(getWebSocket())
    if (lastJsonMessage) {
      const data:any = lastJsonMessage
      console.log(lastJsonMessage)
      if(data['userId'] === userId){
        dispatch(homeSliceActions.setPdfParsed(data))
      } else if(data['type']==="CHAT_ANSWER"){
        dispatch(homeSliceActions.setMessage({status: data['status'], ...data['message']}))
      } else if(data['type']==="CHAT_ERROR"){
        dispatch(homeSliceActions.setMessage({status: data['status'], ...data['message']}))
      }
      
      console.log(lastJsonMessage)
    }
  }, [lastJsonMessage]);
  const sendChatMessage = (data:any) =>{
    console.log("sendChatMessage")
    console.log(data)
    sendJsonMessage({action:"send-message",data})
  }
  useEffect(()=>{

  },[])
  return (
    <Box minHeight="100vh" display="flex" flexDirection="column" sx={{
			background: 'linear-gradient(117.44deg, #150425 -6.61%, #020116 99.5%)',
      '& ::-webkit-scrollbar-track':
      {
        borderRadius: '10px',
        backgroundColor: '#555'
      },
      '& ::-webkit-scrollbar':
      {
        width: '4px',
        height: '4px',
        backgroundColor: 'transparent'
      },

      '& ::-webkit-scrollbar-thumb':
      {
        borderRadius: '10px',
        backgroundColor: '#F5F5F5'
      }
    }}>
      <SocketContext.Provider value={{sendChatMessage}}>
        <ToastNotification />
        {!window.location.pathname.includes('search') && (
          <Box
            sx={{
              position: 'absolute',
              cursor: 'pointer',
              top: {xs:'20px', sm:'30px', md:'30px', lg:'30px'},
              pb: '10px',
              left: {xs:'12px', sm:'50px', md:'60px', lg:'60px'},
              '& img': {
                width: {xs:'30px', sm:'40px', md:'40px', lg:'40px'},
                height:  {xs:'30px', sm:'40px', md:'40px', lg:'40px'},
              }
            }}
            onClick={() => navigate('/')}
          >
            <img
              alt="pdf"
              src={OpenAILogo}
            />
          </Box>
        )}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/chat/:id" element={<Chatting />} />
          <Route path="/embedchat" element={<EmbedChat />} />
          <Route path="/search/:id" element={<Search />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/history" element={<HistoryPage />} />
        </Routes>
      </SocketContext.Provider>
    </Box>
  );
}

export default Routers;
