import { Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToastNotification = () => {
  return (
		<Box sx={{
			'& .Toastify__toast-container': {
				width: '430px',
			},
			'& .Toastify__toast': {
				background: '#10071E',
				border: '1px solid rgba(102, 193, 246, 0.3)',
				borderRadius: '6px',
				overflow: 'unset',
				p: 0
			},
			'& .Toastify__toast.Toastify__toast--error': {
				borderTop: '5px solid #BC2F30'
			},
			'& .Toastify__toast.Toastify__toast--info': {
				borderTop: '5px solid #3056E0'
			},
			'& .Toastify__toast.Toastify__toast--warning': {
				borderTop: '5px solid #ED6868'
			},
			'& .Toastify__toast.Toastify__toast--success': {
				borderTop: '5px solid #60EB7F'
			},
			'& .Toastify__toast-body': {
				p: 0,
				position: 'relative'
			},
			'& .Toastify__close-button': {
				position: 'absolute',
				top: '-18px',
				right: '-18px',
				background: '#10071E',
				border: '1px solid rgba(102, 193, 246, 0.3)',
				boxShadow: '0px 10px 84px 40px rgba(102, 193, 246, 0.07)',
				borderRadius: '50%',
				width: '34px',
				height: '34px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				color: '#FFFFFF',
				opacity: 1
			}
		}}>
			<ToastContainer icon={false} position="top-center" hideProgressBar autoClose={5000} />
		</Box>
  );
};
