import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { HomeState } from "./types";
import { v4 as uuidv4 } from 'uuid';

export const initialState: HomeState = {
    userId:uuidv4(),
    pdfParsedData: {
			userId:'',
			fileName:'',
			pdfParsed: false,
			message: ''
    },
		message:{
			role: '',
			content: '',
			status: false,
    }
};

export const homeSlice = createSlice({
	name: "home",
	initialState,
	reducers: {
		setPdfParsed(state, action: PayloadAction<any>) {
			state.pdfParsedData = action.payload
			// if(action.payload.pdfParsed){
			// 	setTimeout(()=>{
			// 		state.pdfParsedData.message = ''
			// 	},5000)
			// }
		},
		initState(state) {
			state.userId = uuidv4();
			state.pdfParsedData = initialState.pdfParsedData;
			state.message = initialState.message
		},
		removeParsed(state, action: PayloadAction<any>) {
			state.pdfParsedData = action.payload
		},
		setMessage(state, action: PayloadAction<any>) {
			state.message = action.payload
		},
	},
});

export const { actions: homeSliceActions } = homeSlice;
