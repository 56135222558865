import type { FC } from 'react';
import type { ApexOptions } from 'apexcharts';
import { useTheme } from '@mui/material/styles';
import Chart from 'react-apexcharts';
import {Box} from '@mui/material';

interface LineChartProps {
	width?: string|number;
	height?: string|number;
	chartSeries: any[];
  categories: string[];
	chartOptions?: ApexOptions;
  colors?: string[];
};

export const LineChart: FC<LineChartProps> = (props) => {
  const { chartSeries, categories, chartOptions, width, height, colors, ...other } = props;
  const theme = useTheme();

  const optionLine: ApexOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
      selection: {
        enabled: false
      },
    },
    colors: colors,
    dataLabels: {
      enabled: false,  
    },
		plotOptions: {
			bar: {
				columnWidth: "20%"
			}
		},
    grid: {
      borderColor: theme.palette.divider,
      column: {
          colors: ['rgba(36, 21, 61, 0.34)', 'transparent'],
          opacity: 0.5
      }, 
      padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
      },
    },
    markers: {
      strokeColors: theme.palette.background.paper,
      size: 6
    },
    stroke: {
      curve: 'straight',
			colors: colors,
      width: 2
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: categories,
      labels: {
        style: {
          colors: '#545881'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#545881'
        }
      }
    },
		legend: {
			show: false
		}
  };

  return (
    <Box>
      <Chart
        width={width}
        height={height}
        options={{...chartOptions, ...optionLine}}
        series={chartSeries}
        type="line"
      />
    </Box>
  );
};
