import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Dropzone, { useDropzone } from 'react-dropzone';
import type { DropzoneOptions } from 'react-dropzone';
import {
  Box,
  Typography
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface FileDropzoneProps extends DropzoneOptions {
  files?: any[];
	isLoading: boolean;
  onRemove?: (file: any) => void;
  onRemoveAll?: () => void;
  onUpload?: () => void;
}

export const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const {
    accept,
    disabled,
		isLoading,
    files,
    getFilesFromEvent,
    maxFiles,
    maxSize,
    minSize,
    noClick,
    noDrag,
    noDragEventsBubbling,
    noKeyboard,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onRemove,
    onRemoveAll,
    onUpload,
    preventDropOnDocument,
    ...other
  } = props;

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop
  });

  return (
    <Box {...other}>
			<Dropzone onDrop={onDrop} multiple={false} accept={accept}>
				{({getRootProps, getInputProps}) => (
					<Box
						sx={{
							alignItems: 'center',
							border: 1,
							borderRadius: 1,
							borderStyle: 'dashed',
							borderColor: 'divider',
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'center',
							outline: 'none',
							backgroundColor: '#10071E',
							p: 2,
							pt: { xs: '55px', sm: '34px', md: '23px', lg: '23px' },
							...(
								isDragActive && {
									backgroundColor: 'action.active',
									opacity: 0.5
								}
							),
							'&:hover': {
								backgroundColor: 'action.hover',
								cursor: 'pointer',
								opacity: 0.5
							}
						}}
						{...getRootProps()}
					>
						<input {...getInputProps()} />
						<Box>
							<Box>
								<Box sx={{textAlign: 'center'}}>
									<Typography sx={{
										fontWeight: '600',
										fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '16px' },
										lineHeight: { xs: '31px', sm: '18px', md: '31px', lg: '31px' },
									}}>
										Choose a file saved on your computer
									</Typography>
									<Box sx={{
										pt: { xs: '28px', sm: '30px', md: '10px', lg: '10px' },
										display: 'flex',
										flexDirection: {xs: 'column', sm: 'column', md: 'row', lg: 'row'},
										alignItems: 'center', justifyContent: 'center',
										gap: { xs: '25px', sm: '18px', md: '12px', lg: '12px' },
									}}
									>
										<AddCircleIcon sx={{color: '#66C1F6', fontSize: {xs: '60px', sm: '48px', md: '24px', lg: '24px'}}}/>
										<Typography sx={{
										fontWeight: '400',
										fontSize: { xs: '13px', sm: '10px', md: '14px', lg: '14px' },
										lineHeight: { xs: '31px', sm: '18px', md: '31px', lg: '31px' },
									}}>
											Upload now
										</Typography>
									</Box>
									<Typography sx={{
										pt: { xs: '27px', sm: '12px', md: '10px', lg: '10px' },
										fontWeight: '400',
										fontSize: { xs: '8px', sm: '10px', md: '10px', lg: '10px' },
										lineHeight: { xs: '31px', sm: '18px', md: '31px', lg: '31px' },
										opacity: .3
									}}>
										Please note that the upload limit for PDF files is 1.3 MB
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				)}
			</Dropzone>
    </Box>
  );
};

FileDropzone.propTypes = {
	accept: PropTypes.any,
  disabled: PropTypes.bool,
  files: PropTypes.array,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  preventDropOnDocument: PropTypes.bool
};

FileDropzone.defaultProps = {
  files: []
};
