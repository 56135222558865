import type { FC } from 'react';
import { Box, Card, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface ErrorChatMessageProps {
  content: string;
}

export const ErrorChatMessage: FC<ErrorChatMessageProps> = (props) => {
  const { content } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: 700,
        width: 'fit-content',
        ml: 0,
        mb: 2
      }}
    >
      <Box>
        <Card
          sx={{
						background: 'linear-gradient(117.44deg, #150425 -6.61%, #020116 99.5%)',
						border: '1px solid rgba(188, 47, 48, 0.3)',
						borderRadius: '15px',
						p: 2,
						display: 'flex',
						color: '#FFFFFF',
          }}
        >
					<ErrorOutlineIcon sx={{fontSize: '34px', mr: 3, color: '#BC2F30'}}/>
					<Typography
						color="inherit"
						sx={{
							fontSize: {xs: '11px', sm: '14px'},
							lineHeight: {xs: '19px', sm: '23px'},
						}}
					>
						{content}
					</Typography>
				</Card>
			</Box>
		</Box>
  );
};
