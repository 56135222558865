import { useEffect, useRef, useState } from 'react';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, CircularProgress, IconButton, TextField, Tooltip } from '@mui/material';
import { PaperAirplane as PaperAirplaneIcon } from '../icons/paper-airplane';
import { Photograph as PhotographIcon } from '../icons/photograph';
import { PaperClip as PaperClipIcon } from '../icons/paper-clip';

interface ChatMessageAddProps {
  initialValue?: string;
  disabled?: boolean;
  isLoading: boolean;
  onSend?: (value: string) => void;
}

export const ChatMessageAdd: FC<ChatMessageAddProps> = (props) => {
  const { initialValue, disabled, isLoading, onSend, ...other } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [body, setBody] = useState<string>('');
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  const user = {
    avatar: '/static/mock-images/avatars/avatar-anika_visser.png',
    name: 'Anika Visser'
  };

  const handleAttach = (): void => {
    if(fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setBody(event.target.value);
  };

  const handleSend = (): void => {
    if (!body) {
      return;
    }

    onSend?.(body);
    setBody('');
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  useEffect(() => {
    setBody(initialValue?initialValue:'')
  }, [initialValue]);

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexShrink: 0,
        p: 3,
        pt: 1
      }}
      {...other}
    >
      {/* <Avatar
        sx={{
          display: {
            xs: 'none',
            sm: 'inline'
          },
          mr: 2
        }}
        src={user.avatar}
      /> */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
        }}
      >
        <TextField
          disabled={disabled}
          fullWidth
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          placeholder="Ask any question"
          value={body}
          size="small"
          sx={{
            '& .MuiInputBase-root': {
              border: '1px solid rgba(102, 193, 246, 0.3)',
              borderRadius: 4,
              py: 1,
            }
          }}
          inputProps={{style: {paddingRight: '60px'}}}
        />
        {/* {isLoading && (
          <CircularProgress
            size={20}
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              margin: 'auto 0',
              right: '50px'
          }}
          />
        )} */}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            position: 'absolute',
            top: 0,
            bottom: 0,
            margin: 'auto 0',
            right: '10px'
          }}
        >
          <Tooltip title="Send">
            <Box sx={{ m: 1 }}>
              <IconButton
                disabled={!body || disabled}
                sx={{
                  color: 'neutral.400',
                  '&:hover': {
                    color: 'neutral.100'
                  }
                }}
                onClick={handleSend}
              >
                <PaperAirplaneIcon fontSize="small" />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <input
        hidden
        ref={fileInputRef}
        type="file"
      />
    </Box>
  );
};

ChatMessageAdd.propTypes = {
  disabled: PropTypes.bool,
  onSend: PropTypes.func
};

ChatMessageAdd.defaultProps = {
  disabled: false
};
