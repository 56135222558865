import * as React from 'react';
import type { ChangeEvent } from 'react';
import {
	Container,
  Box,
  Grid,
  Typography,
	Card,
	MenuItem,
	TextField
} from '@mui/material';
import { Api, BASE_URL } from '../../api/api';
import { useSelector, useDispatch } from 'react-redux';
import { LineChart } from '../../components/chart/LineChart';
import { DonutChart } from '../../components/chart/DonutChart';

const uploadChartSeries = [
	{
		name: 'Upload',
		data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
	}
];

const questionChartSeries = [
	{
		name: 'Question',
		data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
	}
];

const weekCategories = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const monthCategories = ['Week1', 'Week2', 'Week3', 'Week4'];
const yearCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function Analytics() {
	const [period, setPeriod] = React.useState<string>('year');
	const [categories, setCategories] = React.useState<string[]>([]);
	const [uploadData, setUploadData] = React.useState<any>(uploadChartSeries);
	const [questionData, setQuestionData] = React.useState<any>(questionChartSeries);
	const [data, setData] = React.useState<any>();

	const handlePeriodChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setPeriod(event.target.value);
	}

	React.useEffect(()=>{
		switch (period) {
			case "week":
				setCategories(weekCategories);
				if(data) {
					setUploadData([{
						name: 'Upload',
						data: data?.week.upload
					}]);
					setQuestionData([{
						name: 'Question',
						data: data?.week.question
					}]);
				}
				break;

			case "month":
				setCategories(monthCategories);
				if(data) {
					setUploadData([{
						name: 'Upload',
						data: data?.month.upload
					}]);
					setQuestionData([{
						name: 'Question',
						data: data?.month.question
					}]);
				}
				break;
		
			case "year":
				setCategories(yearCategories);
				if(data) {
					setUploadData([{
						name: 'Upload',
						data: data?.year.upload
					}]);
					setQuestionData([{
						name: 'Question',
						data: data?.year.question
					}]);
				}
				break;

			default:
				break;
		}
	},[period, data]);

	const getReportData = async () => {
		Api.post(BASE_URL, '/report', {filter: period})
			.then(resp => {
				if(resp.statusCode === 200) {
					setData(resp.data)
				}
			})
			.catch(e => {

			})
	};

	React.useEffect(() => {
		getReportData();
	}, [period]);

  return (
		<Box sx={{
			minHeight: '100vh'
		}}>
      <Box sx={{ pb: 5, px: {xs: 2, sm: 8, md: 8, lg: 8}, color: 'neutral.100' }}>
				<Container fixed sx={{pt:10}}>
					<Typography variant='h3' sx={{
						textAlign: 'center',
						pb: 10
					}}
					>
						Analytics
					</Typography>
					<Grid
						container
						spacing={4}
					>
						<Grid
							item
							md={4}
							sm={6}
							xs={12}
						>
							<Card sx={{
								background: '#10071E',
								border: '1px solid rgba(102, 193, 246, 0.3)',
								borderRadius: '6px',
								boxShadow: '0px 10px 84px 40px rgba(102, 193, 246, 0.1)'
							}}>
								<Box
									sx={{
										alignItems: 'center',
										display: 'flex',
										pr: 3,
										py: 1
									}}
								>
									<DonutChart
										width={120}
										height={120}
										chartSeries={[80, 20]}
										colors={['#66C1F6', '#FFFFFF']}
									/>
									<div>
										<Typography
											variant="h3"
										>
											{data?.total_user || 0}
										</Typography>
										<Typography
											color="textSecondary"
										>
											Total Users
										</Typography>
									</div>
								</Box>
							</Card>
						</Grid>
						<Grid
							item
							md={4}
							sm={6}
							xs={12}
						>
							<Card sx={{
								background: '#10071E',
								border: '1px solid rgba(102, 193, 246, 0.3)',
								borderRadius: '6px',
								boxShadow: '0px 10px 84px 40px rgba(102, 193, 246, 0.1)'
							}}>
								<Box
									sx={{
										alignItems: 'center',
										display: 'flex',
										pr: 3,
										py: 1
									}}
								>
									<DonutChart
										width={120}
										height={120}
										chartSeries={[80, 20]}
										colors={['#CA50DE', '#FFFFFF']}
									/>
									<div>
										<Typography
											variant="h3"
										>
											{data?.total_uploads || 0}
										</Typography>
										<Typography
											color="textSecondary"
										>
											Total Uploads
										</Typography>
									</div>
								</Box>
							</Card>
						</Grid>
						<Grid
							item
							md={4}
							sm={6}
							xs={12}
						>
							<Card sx={{
								background: '#10071E',
								border: '1px solid rgba(102, 193, 246, 0.3)',
								borderRadius: '6px',
								boxShadow: '0px 10px 84px 40px rgba(102, 193, 246, 0.1)'
							}}>
								<Box
									sx={{
										alignItems: 'center',
										display: 'flex',
										pr: 3,
										py: 1
									}}
								>
									<DonutChart
										width={120}
										height={120}
										chartSeries={[80, 20]}
										colors={['#26D39F', '#FFFFFF']}
									/>
									<div>
										<Typography
											variant="h3"
										>
											{data?.total_questions || 0}
										</Typography>
										<Typography
											color="textSecondary"
										>
											Total Questions
										</Typography>
									</div>
								</Box>
							</Card>
						</Grid>
					</Grid>

					<Box sx={{display: 'flex', alignItems: 'center', pt:8}}>
						{/* <Typography>Period</Typography> */}
						<TextField
							defaultValue="week"
							select
							size="small"
							sx={{ 
								width: '150px',
								boxShadow: '0px 10px 84px 40px rgba(102, 193, 246, 0.1)',
								'& .MuiOutlinedInput-notchedOutline':{
									border: '1px solid rgba(102, 193, 246, 0.3)',
								}
							}}
							value={period}
							onChange={handlePeriodChange}
						>
							<MenuItem value="week">Week</MenuItem>
							<MenuItem value="month">Month</MenuItem>
							<MenuItem value="year">Year</MenuItem>
						</TextField>
					</Box>
					
					<Box
						sx={{
							mt: 8
						}}
					>
							<Typography variant='h5' sx={{pb: 3}}>Uploads</Typography>
								<LineChart chartSeries={uploadData} categories={categories} colors={['#CA50DE']} height={360} />
					</Box>

					<Box
						sx={{
							mt: 8
						}}
					>
						<Typography variant='h5' sx={{pb: 3}}>Questions</Typography>
						<LineChart chartSeries={questionData} categories={categories} colors={['#26D39F']} height={360} />
					</Box>
				</Container>
      </Box>
    </Box>
  );
}

export default Analytics;
