import 'simplebar/dist/simplebar.min.css';
import { forwardRef } from 'react';
import type { MutableRefObject } from 'react';
import SimpleBar from 'simplebar-react';
import type {Props as SimpleBarProps} from 'simplebar-react';
import type { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { SxProps } from '@mui/system';

interface ScrollbarProps extends SimpleBarProps {
  ref: MutableRefObject<typeof SimpleBar>;
  sx?: SxProps<Theme>;
}

const ScrollbarRoot = styled(SimpleBar)``;

export const Scrollbar = forwardRef<any, ScrollbarProps>((props, ref) => {
  return (
    <ScrollbarRoot
      {...props}
      ref={ref}
    />
  );
});
