import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../types/root-state";
import { initialState } from "./slice";

const selectDomain = (state: RootState) =>
	state["home"] || initialState;

export const selectUserId = createSelector(
	[selectDomain],
	(selectHome) => selectHome.userId
);

export const selectPdfParsedData = createSelector(
	[selectDomain],
	(selectHome) => selectHome.pdfParsedData
);
export const selectMessage = createSelector(
	[selectDomain],
	(selectHome) => selectHome.message
);
