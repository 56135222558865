import { useCallback, useState } from 'react';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Search as SearchIcon } from '../icons/search';
import { BoxProps } from '@mui/material/Box';

interface SearchInputProps extends BoxProps {
  disabled?: boolean;
  onSearch?: (value: string) => void;
  onSend?: (value: string) => void;
	questions: [];
}

export const SearchInput: FC<SearchInputProps> = (props) => {
  const { disabled, onSearch, onSend, questions, ...other } = props;
  const [body, setBody] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setBody(event.target.value);

    onSearch?.(event.target.value);
  };

  const handleSend = (): void => {
    if (!body) {
      return;
    }

    onSend?.(body);
    setBody('');
  };

	const selectQuestion = (value:string) => {
		onSend?.(value)
		setBody('');
	};

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexShrink: 0,
        p: 3,
        pt: 1
      }}
      {...other}
    >
      <Box
        sx={{
          width: '100%',
					border: '1px solid rgba(102, 193, 246, 0.3)',
					borderRadius: 4,
					background: '#170D27',
        }}
      >
				<Box
					sx={{
						position: 'relative',
					}}
				>
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
							position: 'absolute',
							top: 0,
							bottom: 0,
							margin: 'auto 0',
							right: '10px'
						}}
					>
						<Tooltip title="Search">
							<Box sx={{ m: 1 }}>
								<IconButton
									sx={{
										zIndex: 1000,
										color: 'neutral.400',
										'&:hover': {
											color: 'neutral.100'
										}
									}}
									onClick={handleSend}
								>
									<SearchIcon fontSize="small" />
								</IconButton>
							</Box>
						</Tooltip>
					</Box>
					<TextField
          	disabled={disabled}
						fullWidth
						size='small'
						onChange={handleChange}
						onKeyUp={handleKeyUp}
						value={body}
						sx={{
							'& .MuiInputBase-root': {
								border: 'none',
								py: 1,
							},
							'& .MuiOutlinedInput-notchedOutline': {
								border: 'none'
							}
						}}
						inputProps={{style: {paddingRight: '60px'}}}
					/>
				</Box>
				{questions.length > 0 && (
					<Box
						sx={{
							borderTop: '1px solid rgba(102, 193, 246, 0.08)',
							px: {xs: '27px', sm: '58px', md: '58px'},
							py: {xs: '24px', sm: '24px', md: '24px'},
							maxHeight: 'calc(100vh - 445px)',
							overflowY: 'auto',
						}}
					>
						{questions.map((question: any, index) => {
							return(
								<div key={index}>
									<Typography
										sx={{
											py: '20px',
											fontSize: '14px',
											cursor: 'pointer'
										}}
										onClick={()=>selectQuestion(question)}
									>
									{question}
									</Typography>
									{index !== questions.length - 1 && (
										<Divider/>
									)}
								</div>
							)
						})
					}
					</Box>
				)}
      </Box>
    </Box>
  );
};

SearchInput.propTypes = {
  onSearch: PropTypes.func
};

SearchInput.defaultProps = {
};
