import * as React from 'react';
import {
	Container,
  Box,
  Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PDFIcon from '../../assets/pdficon.svg';

interface QuestionItemProps {
	question: string;
	answer: string;
	time: string;
};

const QuestionItem: React.FC<QuestionItemProps> = (props) => {
	const { question, answer, time, ...other } = props;
	const [isClicked, setIsClicked] = React.useState<boolean>(false);

	return (
		<Box sx={{
			background: 'linear-gradient(117.44deg, #150425 -6.61%, #020116 99.5%)',
			border: '1px solid #66C1F64D',
			borderRadius: '15px'
		}}>
			<Box
				onClick={() => setIsClicked(prev => !prev)}
				sx={{
					cursor: 'pointer',
					position: 'relative',
					pl: 4,
					pr: 6,
					py: 3,
				}}
			>
				<Box
					sx={{
						cursor: 'pointer',
						display: 'flex',
						justifyContent: 'space-between',
						fontSize: '16px',
						fontWeight: '500',
					}}
				>
					<Typography
						sx={{
							mr: 2,
						}}
					>{question}</Typography>
					<Typography
						sx={{
							color: '#FFFFFF80'
						}}
					>{time}</Typography>
				</Box>
				<Box sx={{
					position: 'absolute',
					right: '22px',
					top: '50%',
					transform: "translateY(-50%)",
					height: '24px',
					color: '#66C1F6'
				}}>
				{!isClicked && <ArrowDropUpIcon />}
				{isClicked && <ArrowDropDownIcon />}
				</Box>
			</Box>
			{isClicked &&
				<Box sx={{
					px: 4,
					py: 3,
					borderTop: '1px solid #66C1F64D',
				}}>
					<Typography
						sx={{
						fontSize: '14px',
						fontWeight: '300'
						}}
					>{answer}</Typography>
				</Box>
			}
		</Box>
	)
}
interface HistoryItemProps {
	fileName: string;
	uploadedTime?: string;
	questionData: any[];
  onClickBack?: () => void;
};

const HistoryItem: React.FC<HistoryItemProps> = (props) => {
	const { fileName, uploadedTime, questionData, onClickBack, ...other } = props;

	const convertDateTimeFormat = (isoStr:string) => {
		const dateObject = new Date(isoStr);
		const formattedDate = `${dateObject.getFullYear()}-${('0' + (dateObject.getMonth()+1)).slice(-2)}-${('0' + dateObject.getDate()).slice(-2)}, ${('0' + dateObject.getHours()).slice(-2)}:${('0' + dateObject.getMinutes()).slice(-2)}`;
		return formattedDate
	}

  return (				
		<Box sx={{height: 'calc(100% - 20px)', width: '100%'}}>
			<Box sx={{overflowY: 'auto'}}>
				<Table sx={{ minWidth: 650, '& td, & th': { borderColor: '#66C1F64D !important', fontSize: '16px', fontWeight: '500', color: '#FFFFFF4D' } }} aria-label="simple table">
					<TableHead sx={{background: 'transparent'}}>
						<TableRow>
							<TableCell>Document name</TableCell>
							<TableCell align="left">Uploaded time</TableCell>
							<TableCell align="left">Questions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow
							onClick={()=>onClickBack?.()}
						>
							<TableCell component="th" scope="row">
								<Box sx={{
									display: 'flex',
									alignItems: 'center',
									color: '#FFFFFF',
									fontSize: '18px',
									fontWeight: '500',
									'& img': {
										width: {xs: '20px', sm: '29px'},
										mr: 2
									}
								}}>
									<img
										alt="pdf"
										src={PDFIcon}
									/>
									{fileName}
								</Box>
							</TableCell>
							<TableCell align="left">{uploadedTime ? convertDateTimeFormat(uploadedTime) : ''}</TableCell>
							<TableCell align="left">{questionData.length}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Box>
			<Box sx={{
				p: 3,
				height: 'calc(100% - 180px)',
				overflowY: 'auto',
			}}>
				<Typography sx={{pb: 3}}>Question List</Typography>
				{questionData.map((item, index) => {
					return (
						<Box sx={{pb: '11px'}}>
							<QuestionItem key={`question_item_${index}`} question={item.question} answer={item.answer} time={item.created_date? convertDateTimeFormat(item.created_date) : ''} />
						</Box>
					)
				})}
			</Box>
		</Box>
  );
}

export default HistoryItem;
