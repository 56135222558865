import * as React from "react";
import {
  Box,
  Typography,
  ButtonGroup,
  Button,
  Switch,
  CircularProgress,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import { Api, BASE_URL } from "../../api/api";
import { ChatMessages } from "../../components/chat-messages";
import { ChatMessageAdd } from "../../components/chat-message-add";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { homeSliceActions } from "../Home/slice";
import PDFIcon from "../../assets/pdficon.svg";
import CodeIcon from "../../assets/codesnip.svg";
import { SocketContext } from "../../routes/context";
import { selectMessage } from "../Home/selectors";
import { SearchInput } from "../../components/search-input";
import { OptionPanelProps, EmbedStepItemProps } from "./types";
import History from "../../components/history/History";
import HistoryItem from "../../components/history/HistoryItem";

interface HistoryInterface {
  id: string;
  file_name: string;
  created_date: string;
  questions: number;
}

interface HistoryItemInterface {
  id: string;
  file_name: string;
  created_date: string;
  questionData: any[];
}

function OptionPanel(props: OptionPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box hidden={value !== index} {...other}>
      {children}
    </Box>
  );
}

function EmbedStepItem(props: EmbedStepItemProps) {
  const { sx, step, detail, ...other } = props;
  return (
    <Box
      sx={{
        border: "1px solid rgba(102, 193, 246, 0.3)",
        // boxShadow: '0px 10px 84px 40px rgba(102, 193, 246, 0.07)',
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        maxWidth: { sm: "188px" },
        px: { xs: 4, sm: 3, md: 3, lg: 4 },
        py: 3,
        mb: { xs: 3, sm: 0 },
      }}
      {...other}
    >
      <Typography
        sx={{
          color: "#66C1F6",
          fontWeight: "700",
          fontSize: { xs: "13px", sm: "13px", md: "13px", lg: "13px" },
          lineHeight: { xs: "20px", sm: "20px", md: "20px", lg: "20px" },
          letterSpacing: "0.5em",
        }}
      >
        {step}
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          lineHeight: "22px",
          fontWeight: 600,
          textAlign: "start",
          pt: 3,
        }}
      >
        {detail}
      </Typography>
    </Box>
  );
}

function Chatting() {
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [sending, setSending] = React.useState<boolean>(false);
  const [isCopied, setIsCopied] = React.useState<boolean>(false);
  const [isIframe, setIsIframe] = React.useState<boolean>(true);
  const [fileNameShort, setFileNameShort] = React.useState<string>("");
  const [inputValue, setInputValue] = React.useState<string>("");
  const [updateMsgID, setMsgID] = React.useState<number>();
  const [activeOptionID, setActiveOptionID] = React.useState<number>(1);
  const [histories, setHistory] = React.useState<any[]>([]);
  const messagesRef = React.useRef<any>(null);
  const [thread, setThread] = React.useState<any>([]);
  const messagesSearchRef = React.useRef<any>(null);
  const [searchResult, setSearchResult] = React.useState<any>([]);
  const [searched, setSearched] = React.useState<boolean>(false);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const params = useParams().id;
  const identifier = params?.split(":")[0];
  const fileName = params?.split(":")[1];

  const [data, setData] = React.useState<HistoryInterface[]>();
  const [item, setItem] = React.useState<HistoryItemInterface>();
  const [isClickedRow, setIsClickedRow] = React.useState<boolean>(false);

  const handleClickRow = (id: string, fileName: string, createdAt: string) => {
    getHistoryItemData(id, fileName, createdAt);
    setIsClickedRow(true);
  };

  const { sendChatMessage } = React.useContext(SocketContext);
  const messageData = useSelector(selectMessage);

  const [value, setValue] = React.useState(1);

  const handleOptionChange = (id: number) => {
    setValue(id);
    setActiveOptionID(id);
  };

  React.useEffect(() => {
    if (messageData.role) {
      setLoading(false);
      setSending(false);
      setThread((prev: any) => {
        return [
          ...prev,
          {
            ...messageData,
            type:
              activeOptionID === 1 || prev.length === 0 ? "chat" : "question",
          },
        ];
      });
      setHistory((prev: any) => {
        return [
          ...prev,
          {
            author: messageData.role === "assistant" ? "AI" : "Human",
            msg: messageData?.content,
          },
        ];
      });
    }
  }, [messageData]);

  console.log("histories", histories);

  console.log("id ==========>", thread);

  const bounce = keyframes`
		0% {
			transform: translateY(0px);
		}
		50% {
			transform: translateY(8px);
		}
		100% {
			transform: translateY(0px);
		}
	`;

  const handleSendMessage = React.useCallback(
    (msg: string) => {
      let newThread = thread;
      if (updateMsgID != null) {
        newThread[0].questions = newThread[0].questions.map(
          (question: any, index: number) => {
            if (index === updateMsgID) return msg;
            return question;
          }
        );
      }
      setSending(true);
      setThread((prev: any) => {
        return [
          ...newThread,
          {
            role: "user",
            content: msg,
            type: "chat",
            status: true,
          },
        ];
      });
      setHistory((prev: any) => {
        return [
          ...prev,
          {
            author: "Human",
            msg: msg,
            type: "chat",
            status: true,
          },
        ];
      });
      sendChatMessage({
        identifier: identifier,
        fileName: fileName,
        history: histories,
        question: msg,
      });
    },
    [thread, updateMsgID, sendChatMessage, identifier, fileName, histories]
  );

  React.useEffect(() => {
    const element = messagesRef.current;
    if (element) element.scrollTop = element.scrollHeight;
  }, [messagesRef.current?.scrollHeight]);

  const handleScrollDown = () => {
    const element = messagesRef.current;
    element.scrollTop = element.scrollHeight;
  };

  React.useEffect(() => {
    if (params) {
      if (identifier && fileName) {
        let split = fileName.split(".");
        split.pop();
        setFileNameShort(split.join("."));
        sendChatMessage({
          identifier: identifier,
          fileName: fileName,
          history: histories,
          question: null,
        });
        getHistoryData(identifier);
      }
      dispatch(homeSliceActions.setPdfParsed({}));
    }
  }, []);

  // search option
  const handleSearchMessage = (str: string) => {
    if (!str) {
      setSearchResult([]);
    } else {
      if (thread.length > 0) {
        if (thread[0].role === "assistant") {
          let exampleQuestions = thread[0].questions;
          let foundQuestions = exampleQuestions.filter((question: string) =>
            question.toLowerCase().includes(str.toLowerCase())
          );
          setSearchResult(foundQuestions);
        }
      }
    }
  };

  const handleSearchSendMessage = React.useCallback(
    (msg: string) => {
      if (msg) {
        setSending(true);
        setSearched(true);
        setSearchResult([]);
        setThread((prev: any) => {
          return [
            ...prev,
            {
              role: "user",
              content: msg,
              type: "question",
              status: true,
            },
          ];
        });
        setHistory((prev: any) => {
          return [
            ...prev,
            {
              author: "Human",
              msg: msg,
              type: "question",
              status: true,
            },
          ];
        });
        sendChatMessage({
          identifier: identifier,
          fileName: fileName,
          history: histories,
          question: msg,
        });
      }
    },
    [histories, setThread, setSending, setHistory, identifier, fileName]
  );

  React.useEffect(() => {
    const element = messagesSearchRef.current;
    if (element) element.scrollTop = element.scrollHeight;
  }, [messagesSearchRef.current?.scrollHeight, thread]);

  const handleSearchScrollDown = () => {
    const element = messagesSearchRef.current;
    element.scrollTop = element.scrollHeight;
  };

  //Embed Script option
  const copyToClipboard = () => {
    const str = isIframe
      ? `
			<iframe
				src="https://www.gptfaq.ai/search/${identifier}:${encodeURI(fileNameShort)}.pdf"
				width="800"
				height="540"
			/>`
      : `<div style="max-width: 400px; position: absolute; right: 20px; bottom:20px;">
				<div class="gptfaq-widget"
					data-subidentifier="${identifier}"
					data-subfilename="${fileNameShort}">
				</div>
			</div>
			<link href="https://main.d144ns50hgrru.amplifyapp.com/index.css" rel="stylesheet" />
			<script src="https://main.d144ns50hgrru.amplifyapp.com/index.js"></script>`;
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setIsCopied(true);
      return navigator.clipboard.writeText(str);
    }
    return Promise.reject("The Clipboard API is not available.");
  };

  const handleClickCopyScript = () => {
    copyToClipboard();
    setTimeout(function () {
      setIsCopied(false);
    }, 5000);
  };

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsIframe(event.target.checked);
  };

  //history option
  const getHistoryData = async (id: string) => {
    Api.post(BASE_URL, "/list_document_by_id", {
      identifier: id,
    })
      .then((resp) => {
        console.log("resp", resp);
        setData(resp.data);
      })
      .catch((e) => {});
  };

  const getHistoryItemData = async (
    id: string,
    fileName: string,
    createdAt: string
  ) => {
    Api.post(BASE_URL, "/list_question_and_answer", {
      identifier: id,
      file_name: fileName,
    })
      .then((resp) => {
        let questionData = [];
        if (resp?.data?.questions.length > 0) {
          for (let i = 0; i < resp.data.questions.length; i++) {
            questionData.unshift({
              question: resp.data.questions[i].question,
              answer: resp.data.answers[i].answer,
              created_date: resp.data.questions[i].createdAt,
            });
          }
        }
        console.log("questionData", questionData);
        setItem({
          id,
          file_name: fileName,
          created_date: createdAt,
          questionData,
        });
      })
      .catch((e) => {});
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          maxWidth: "1100px",
          margin: "0 auto",
          px: { xs: "12px", sm: 8 },
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            mb: { xs: "20px", sm: "20px", md: "20px", lg: "20px" },
            mt: { xs: "60px", sm: "40px", md: "40px", lg: "40px" },
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            sx={{
              boxShadow: "0px 10px 84px 40px rgba(102, 193, 246, 0.07)",
              borderRadius: "6px",
              background: "transparent",
              "&>button": {
                border: "1px solid rgba(102, 193, 246, 0.3)",
                minWidth: { xs: "auto", sm: "194px !important" },
                fontSize: { xs: "10px", sm: "13px" },
                lineHeight: { xs: "13px", sm: "20px" },
              },
            }}
          >
            <Button
              className={activeOptionID === 0 ? "secondary" : ""}
              onClick={() => handleOptionChange(0)}
            >
              GPT FAQ Q&A
            </Button>
            <Button
              className={activeOptionID === 1 ? "secondary" : ""}
              onClick={() => handleOptionChange(1)}
            >
              Chat with PDF
            </Button>
            <Button
              className={activeOptionID === 2 ? "secondary" : ""}
              onClick={() => handleOptionChange(2)}
            >
              Embed Script
            </Button>
            <Button
              className={activeOptionID === 3 ? "secondary" : ""}
              onClick={() => handleOptionChange(3)}
            >
              History
            </Button>
          </ButtonGroup>
        </Box>
        <Box
          sx={{
            color: "neutral.100",
            backgroundColor: "#10071E",
            borderRadius: 1,
            border: "1px solid rgba(102, 193, 246, 0.3)",
            boxShadow: "0px 10px 114px -30px rgba(102, 193, 246, 0.1)",
            position: "relative",
          }}
        >
          <Box>
            <OptionPanel value={value} index={0}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.05)",
                  p: 3,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {thread && thread?.length > 0 ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: { xs: "1rem", lg: "1.125rem" },
                          textAlign: "left",
                          pl: 2,
                        }}
                      >
                        Search{" "}
                        {thread[0]?.company ? thread[0].company : "Company"}
                      </Typography>
                    </>
                  ) : (
                    <CircularProgress
                      size={20}
                      sx={{
                        top: 0,
                        bottom: 0,
                        margin: "auto 0",
                        right: "50px",
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <SearchInput
                  sx={{
                    position: "absolute",
                    width: "calc(100% - 48px)",
                    top: "15px",
                    mx: 3,
                  }}
                  onSearch={handleSearchMessage}
                  questions={searchResult || []}
                  onSend={handleSearchSendMessage}
                  disabled={isLoading || sending}
                />
                {searched ? (
                  <Box
                    sx={{
                      height: {
                        xs: "calc(100vh - 320px)",
                        sm: "calc(100vh - 320px)",
                        md: "calc(100vh - 320px)",
                        lg: "545px",
                        xl: "545px",
                      },
                      pt: "80px",
                      pb: "24px",
                    }}
                  >
                    <Box
                      ref={messagesSearchRef}
                      sx={{
                        height: "100%",
                        overflow: "auto",
                        "&::-webkit-scrollbar-track": {
                          borderRadius: "10px",
                          backgroundColor: "#555",
                        },
                        "&::-webkit-scrollbar": {
                          width: "8px",
                          backgroundColor: "transparent",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "10px",
                          backgroundColor: "#F5F5F5",
                        },
                      }}
                    >
                      <ChatMessages
                        messages={
                          thread.filter(
                            (item: any) => item.type === "question"
                          ) || []
                        }
                        scrollDown={handleSearchScrollDown}
                      />
                      {(isLoading || sending) && (
                        <Box
                          sx={{
                            pl: 3,
                            pb: 3,
                            "& span": {
                              width: "6px",
                              height: "6px",
                              backgroundColor: "#D9D9D9",
                              display: "inline-block",
                              mr: "4px",
                              borderRadius: "50%",
                              "&:nth-child(1)": {
                                animation: `${bounce} 1s infinite`,
                              },
                              "&:nth-child(2)": {
                                animation: `${bounce} 1s infinite .2s`,
                              },
                              "&:nth-child(3)": {
                                animation: `${bounce} 1s infinite .4s`,
                              },
                            },
                          }}
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      "& img": {
                        width: "150px",
                      },
                      height: {
                        xs: "calc(100vh - 210px)",
                        sm: "calc(100vh - 230px)",
                        md: "calc(100vh - 230px)",
                        lg: "650px",
                        xl: "650px",
                      },
                      textAlign: "center",
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        margin: "auto",
                      }}
                    >
                      <img alt="code" src={CodeIcon} />
                      <Typography
                        sx={{
                          fontSize: { xs: "18px", sm: "25px" },
                          lineHeight: { xs: "19px", sm: "19px" },
                          fontWeight: 600,
                          py: 2,
                        }}
                      >
                        Search your Questions
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </OptionPanel>
            <OptionPanel value={value} index={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.05)",
                  p: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "& img": {
                      width: { xs: "20px", sm: "29px" },
                    },
                  }}
                >
                  {thread && thread?.length > 0 ? (
                    <>
                      <img alt="pdf" src={PDFIcon} />
                      <Typography
                        sx={{
                          fontSize: { xs: "1rem", lg: "1.125rem" },
                          textAlign: "left",
                          pl: 2,
                        }}
                      >
                        Chat with{" "}
                        {!thread[0]?.company ? "Company" : thread[0].company}
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              <Box
                ref={messagesRef}
                sx={{
                  height: {
                    xs: "calc(100vh - 300px)",
                    sm: "calc(100vh - 330px)",
                    md: "calc(100vh - 330px)",
                    lg: "550px",
                    xl: "60vh",
                  },
                  overflow: "auto",
                  "&::-webkit-scrollbar-track": {
                    borderRadius: "10px",
                    backgroundColor: "#555",
                  },
                  "&::-webkit-scrollbar": {
                    width: "8px",
                    backgroundColor: "transparent",
                  },

                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "10px",
                    backgroundColor: "#F5F5F5",
                  },
                }}
              >
                <ChatMessages
                  messages={
                    thread.filter((item: any) => item.type === "chat") || []
                  }
                  onExampleSend={handleSendMessage}
                  onExampleEdit={(msg, index) => {
                    setInputValue(msg);
                    setMsgID(index);
                  }}
                  scrollDown={handleScrollDown}
                />
                {(isLoading || sending) && (
                  <Box
                    sx={{
                      pl: 3,
                      pb: 3,
                      "& span": {
                        width: "6px",
                        height: "6px",
                        backgroundColor: "#D9D9D9",
                        display: "inline-block",
                        mr: "4px",
                        borderRadius: "50%",
                        "&:nth-child(1)": {
                          animation: `${bounce} 1s infinite`,
                        },
                        "&:nth-child(2)": {
                          animation: `${bounce} 1s infinite .2s`,
                        },
                        "&:nth-child(3)": {
                          animation: `${bounce} 1s infinite .4s`,
                        },
                      },
                    }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </Box>
                )}
              </Box>
              <ChatMessageAdd
                initialValue={inputValue}
                isLoading={sending}
                disabled={isLoading || sending}
                onSend={handleSendMessage}
              />
            </OptionPanel>
            <OptionPanel value={value} index={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  py: "4px",
                  px: { xs: "20px", sm: "20px", md: "66px", lg: "66px" },
                }}
              >
                <Typography>Chat with PDF</Typography>
                <Switch
                  focusVisibleClassName=".Mui-focusVisible"
                  checked={isIframe}
                  onChange={handleChangeSwitch}
                />
                <Typography>GPT FAQ Q&A</Typography>
              </Box>
              <Box
                sx={{
                  height: { xs: "auto" },
                  pb: { xs: "16px", sm: "16px", md: "38px", lg: "38px" },
                }}
              >
                <Box
                  sx={{
                    px: { xs: "20px", sm: "20px", md: "66px", lg: "66px" },
                  }}
                >
                  <Box
                    sx={{
                      mb: 5,
                      border: "1px solid rgba(102, 193, 246, 0.3)",
                      px: 2,
                      overflow: "auto",
                    }}
                  >
                    {isIframe ? (
                      <Typography component="pre" variant="body2">
                        {`
</head>
<body>
	<!-- Embed Iframe Start -->
	<iframe
		src="https://www.gptfaq.ai/chat/${identifier}:${encodeURI(fileNameShort)}.pdf"
		width="800"
		height="540"
	/>
	<!-- Embed Iframe End -->
											`}
                      </Typography>
                    ) : (
                      <Typography component="pre" variant="body2">
                        {`
</head>
<body>
	<!-- Embed Script Start -->
	<div style="max-width: 400px; position: absolute; right: 20px; bottom:20px;">
		<div class="gptfaq-widget"
			data-subidentifier="${identifier}"
			data-subfilename="${fileNameShort}">
		</div>
	</div>
	<link href="https://main.d144ns50hgrru.amplifyapp.com/index.css" rel="stylesheet" />
	<script src="https://main.d144ns50hgrru.amplifyapp.com/index.js"></script>
	<!-- Embed Script End -->
											`}
                      </Typography>
                    )}
                  </Box>
                  <Button
                    className="secondary"
                    onClick={handleClickCopyScript}
                    size="medium"
                    sx={{
                      width: { xs: "100%", sm: "auto" },
                      minWidth: "194px",
                      fontSize: { xs: "9px", sm: "13px" },
                      lineHeight: { xs: "11px", sm: "20px" },
                      py: "14px",
                      mb: { xs: 4, sm: 5, md: 5, lg: 5 },
                    }}
                  >
                    {isCopied ? "Copied" : "Copy"}
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                    gridAutoFlow: { sm: "row" },
                    gridTemplateColumns: {
                      sm: "repeat(3, 1fr)",
                      md: "repeat(3, 1fr)",
                      lg: "repeat(3, 1fr)",
                    },
                    px: { xs: "20px", sm: "20px", md: "66px", lg: "66px" },
                  }}
                >
                  <EmbedStepItem
                    step="STEP 1"
                    detail="Please copy chat-widget script to your clipboard by click </> icon."
                  />
                  <EmbedStepItem
                    step="STEP 2"
                    detail="Add copied script into your website inside <body></body> element in the index.html file."
                  />
                  <EmbedStepItem
                    step="STEP 3"
                    detail="Rebuild your web application and deploy."
                  />
                </Box>
              </Box>
            </OptionPanel>

            <OptionPanel value={value} index={3}>
              <Box
                sx={{
                  color: "neutral.100",
                  backgroundColor: "#10071E",
                  borderRadius: 1,
                  border: "1px solid rgba(102, 193, 246, 0.3)",
                  boxShadow: "0px 10px 114px -30px rgba(102, 193, 246, 0.1)",
                  position: "relative",
                  height: "calc(100vh - 160px)",
                  overflowY: "auto",
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
                }}
              >
                {!isClickedRow && (
									<>	
										{data && data.length > 0 ? 
											<History
												historyData={data}
												onClickRow={(id, fileName, createdAt) =>
													handleClickRow(id, fileName, createdAt)
												}
											/>
											:
											<CircularProgress size={30}/>
										}
									</>
                )}
                {isClickedRow && (
									<> 
										{
											item ? 
											<HistoryItem
												fileName={item.file_name}
												uploadedTime={item.created_date}
												questionData={item.questionData}
												onClickBack={() => setIsClickedRow(false)}
											/>
											:
											<CircularProgress size={30}/>
										}
									</>
								)}
              </Box>
            </OptionPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Chatting;
