import type { FC } from 'react';
import type { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

interface DonutChartProps {
	width?: string|number;
	height?: string|number;
	chartSeries: number[];
	chartOptions?: ApexOptions;
	colors?: string[];
};

export const DonutChart: FC<DonutChartProps> = (props) => {
  const { chartSeries, chartOptions, width, height, colors, ...other } = props;
	const optionDonut:ApexOptions = {
		chart: {
				type: 'donut',
		},
		dataLabels: {
			enabled: false,
		},
		plotOptions: {
			pie: {
				customScale: 1,
				expandOnClick: false,
				donut: {
					// size: '75%',
				},
			}
		},
		tooltip: {
			enabled: false
		},
		legend: {
			show: false
		},
		stroke: {
			show: false
		},
		colors: colors,
		states: {

		}
	}

  return (
		<Chart
			width={width}
			height={height}
			options={{...chartOptions, ...optionDonut}}
			series={chartSeries}
			type="donut"
		/>
  );
};
